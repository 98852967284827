










































































import { Component, Vue } from "vue-property-decorator";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";

@Component({
  components: {},
})
export default class JnChiSiamoFirstStrip extends Vue {
  doc = new Document({
    id: "1",
    seq: 1,
    target: "",
    type: "",
    isFragment: false,
    imageSrc: "",
    title: "",
    abstract: "",
    text: "",
    tags: [],
    date: "",
    categories: ["Category"],
    idCategory: "",
    idAreaTematica: "",
    areaTematica: "",
    idMateria: "",
    idArgomento: "",
    materia: "",
    argomento: "",
    author: "",
    descrAuthor: "",
    size: 0,
    highlights: [],
    index: "",
    access: 1,
    since: "",
    to: "",
    sezioneCassazione: "",    
    abrogato: false,
    note: []
  });

  async beforeCreate() {
    this.doc = await EsService.getChiSiamoFirstStrip();
  }
}
