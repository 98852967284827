















import { Component, Vue } from "vue-property-decorator";
import JnProfile from "./ProfileCard.vue";
import { Profile } from "@/models/profile.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";

@Component({
  components: { JnProfile },
})
export default class JnChiSiamoProfilesStrip extends Vue {
  profiles: Profile[] = [];

  async beforeCreate() {
    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES!,
      size: 10,
      from: 0,
    });
    this.profiles = await EsService.getProfiles(searchData);
  }
}
