













import JnChiSiamoFirstStrip from '@/views/chisiamo/ChiSiamoFirstStrip.vue';
import JnChiSiamoSecondStrip from '@/views/chisiamo/ChiSiamoSecondStrip.vue';
import JnChiSiamoThirdStrip from '@/views/chisiamo/ChiSiamoThirdStrip.vue';
import JnChiSiamoProfilesStrip from '@/views/chisiamo/ChiSiamoProfilesStrip.vue';
import JnContattiForm from '@/views/contatti/ContattiForm.vue';
import { Component, Vue } from 'vue-property-decorator';
import MetaInfo from 'vue-meta';

@Component({
  components: {
    JnChiSiamoFirstStrip,
    JnChiSiamoSecondStrip,
    JnChiSiamoThirdStrip,
    JnChiSiamoProfilesStrip,
    JnContattiForm
  },
  metaInfo(this: JnChiSiamo): MetaInfo {
    return { title: 'JuraNews - Chi Siamo' };
  },
})
export default class JnChiSiamo extends Vue {}
